// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

// body {
//   color: black;
// }

// .card-body {
// 	padding-left: 3rem !important;
// 	padding-right: 3rem !important;
// }

.btn_back {
	padding: 8px 28px !important;
	background-color: #281357 !important;
	border-radius: 4px !important;
	color: #fff !important;
	font-weight: bold !important;
}

.label_inquiry {
	font-weight: bold !important;
	font-weight: bold;
	font-size: 14px;
	margin-left: 22px;
}

.lable_span {
	font-weight: bold !important;
	font-size: 12px !important;
}

.widthMobile {
	width: 120px;
}

.btn:visited,
.btn:focus {
	cursor: pointer !important;

	span:visited,
	span:focus {
		cursor: pointer !important;
	}
}

.comporate-image {
	img {
		border-radius: 50%;
	}
}

.PickerSelect {
	// display: flex;
	margin-bottom: 10px;
}

.buttonHeader {
	margin-bottom: 7px;
	margin-right: 5px;
	border-radius: 2px;
	color: #646c9a;
}

.buttonHeaderActive {
	margin-bottom: 7px;
	margin-right: 5px;
	border-radius: 2px;
	color: #fff;
	background-color: #5867dd;

	&:hover {
		color: #fff;
		background-color: #5867dd;
	}
}

.buttonStepHeader {
	margin-bottom: 7px;
	margin-right: 5px;
	border-radius: 2px;
	color: #646c9a;
}

.style_b1 {
	justify-content: center;

	button:nth-child(1) {
		clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
		border-radius: 0;
		border: none;
		padding-right: 30px;
		margin-right: 0px;
	}

	button:nth-child(2),
	button:nth-child(3) {
		border-radius: 0;
		border: none;
		padding-right: 30px;
		padding-left: 30px;
		margin-right: 0px;
		clip-path: polygon(0 0%, 90% 0%, 100% 50%, 90% 100%, 0 100%, 10% 50%);
		min-width: 155px;
	}
}