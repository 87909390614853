//
// Header
//



// Desktop mode
@include kt-desktop {
	.kt-header {
		display: flex;
		justify-content: space-between;
		height: kt-get($kt-header-config, base, desktop, default, height);
		transition: kt-get($kt-aside-config, base, minimize, transition);
		position: relative;
		z-index: 2;

		// Fixed
		&.kt-header--fixed {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: kt-get($kt-header-config, base, desktop, fixed, zindex);
		}

		// Aside enabled
		.kt-aside--enabled & {
			&.kt-header--fixed {
				left: kt-get($kt-aside-config, base, default, width);
			}
		}

		// Fixed and minimized header
		.kt-header--fixed.kt-header--minimize & {
			height: kt-get($kt-header-config, base, desktop, fixed, minimizeHeight);
			transition: height 0.3s ease;
		}

		// Minimize Aside
		.kt-header--fixed.kt-aside--minimize & {
			left: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}
	}

	.kt-header-breadcrumb {
		display: flex;
		justify-content: space-between;
		z-index: 2;
		position: fixed;
		top: -20px;
		right: 0px;
		border-top: 1px solid #eff0f6;
	}

	.breadcrumb-item+.breadcrumb-item::before {
		float: left;
		padding-right: 0.5rem;
		color: #8F9BBA;
		font-size: 20px;
		font-weight: 600;
		content: ".";
		margin-top: -5px;
	}

	.breadcrumb-item {
		a {
			color: #8F9BBA;
		}
	}

	.breadcrumb-item.active {
		color: #1F263E;
	}

	.breadcrumb-item.active::before {
		float: left;
		padding-right: 0.5rem;
		color: #1F263E;
		font-size: 20px;
		font-weight: 600;
		content: ".";
		margin-top: -5px;
	}
}