.f-bold_24 {
  //styleName: Poppin/Po16B (16Bold_24);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.f-semibold_60 {
  //styleName: Poppin/Po40S (40Semibold_60);
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  color: #1F263E !important;
}

.f-semibold_36 {
  //styleName: Poppin/Po24S (24Semibold_36);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.f-semibold_30 {
  //styleName: Poppin/Po20S (20Semibold_30);
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  color: #1F263E !important;
}

.f-semibold_24 {
  //styleName: Poppin/Po16S (16Semibold_24);
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #1F263E;
}

.f-semibold_20 {
  //styleName: Poppin/Po14S (14Semibold_20);
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #1F263E;
}

.f-medium_20 {
  //styleName: Poppin/Po16S (14Medium_20);
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #1F263E;
}

.f-medium_18 {
  //styleName: Poppin/Po16S (12Medium_18);
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: #1F263E;
}

.f-medium_24 {
  //styleName: Poppin/Po16M (16Medium_24);
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #1F263E;
}
.f-medium_24-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.f-regular_20 {
  //styleName: Poppin/Po14R (14Regular_20);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #1F263E;
}

.f-regular_18 {
  //styleName: Poppin/Po12R (12Regular_18);
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #1F263E;
}

.f-regular_11 {
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #1F263E;
}

.default-black-color {
  color: #1F263E !important;
}

.black-color {
  color: #1B2559 !important;
}

.color-grey-300 {
  color: #3B445D !important;
}

.color-grey-500 {
  color: #57617C !important;
}

.color-grey-800 {
  color: #8F9BBA !important;
}

.error-color {
  color: #F42222 !important;
}

.default-grey-color {
  color: #989898 !important;
}

.primary-green-bg {
  background-color: #338FEB !important;
}

.primary-color {
  color: #338FEB !important;
}

.border-color-grey {
  border-color: #99A4C0 !important;
}

.bg-color-grey {
  background-color: #EFF1F5 !important;
}

.bg-color-grey-400 {
  background-color: #F4F7FE !important;
}

.bg-color-grey-800 {
  background-color: #8F9BBA !important;
}

.bg-color-white {
  background-color: #fff !important;
}


@for $i from 0 through 500 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }

  .text-fs-#{$i} {
    font-size: #{$i}px !important;
  }

  .text-fw-#{$i} {
    font-weight: #{$i * 100} !important;
  }

  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}

@for $i from 0 through 200 {
  .h-#{$i} {
    height: #{$i}px !important;
  }
}

@for $i from 150 through 500 {
  .w-#{$i} {
    width: #{$i}px !important;
  }
}

.b-none {
  border: none !important;
}

.hover-table-row {
  border-top: 1px solid rgba(233, 238, 246, 1);

  &:hover {
    background: #F4F7FE;
  }
}

.button-add-circle-fill {
  filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(224deg) brightness(106%) contrast(107%);
}

.purple-circle-fill {
  filter: invert(87%) sepia(14%) saturate(78%) hue-rotate(183deg) brightness(107%) contrast(102%);
}

.dark-circle-fill {
  filter: invert(10%) sepia(14%) saturate(3058%) hue-rotate(191deg) brightness(91%) contrast(87%);
}

.close-circle-fill {
  filter: brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(1537%) hue-rotate(186deg) brightness(92%) contrast(87%);
}

.close-circle-red-fill {
  filter: invert(23%) sepia(60%) saturate(3646%) hue-rotate(349deg) brightness(104%) contrast(98%);
}

.quiz-component {
  .input-group-text {
    background-color: #fff !important;
    border: none;
    margin-right: 0px;
  }

  input[type=radio] {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Chrome, Safari, Opera */
    transform: scale(1.5);
    border-color: #1F263E;
  }

  input[type=checkbox] {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Chrome, Safari, Opera */
    transform: scale(1.5);
    border-color: #1F263E;
  }

  .ant-checkbox-wrapper {
    align-items: center;

    .ant-checkbox {
      margin-top: -3px;
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      border-color: #1F263E;
    }

    .ant-checkbox+span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #1F263E;
    }
  }

  .ant-radio-wrapper {
    align-items: center;

    .ant-radio {
      margin-top: -6px;
      color: #1F263E;
    }

    .ant-radio-inner {
      border: 1px solid #1F263E;
      -ms-transform: scale(1.25);
      /* IE 9 */
      -webkit-transform: scale(1.25);
      /* Chrome, Safari, Opera */
      transform: scale(1.25);
    }

    .ant-radio+span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #1F263E;
    }
  }
}

.input-checkbox-customer {
  .input-group-text {
    padding-left: 0px;
  }
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 1px solid rgba(233, 238, 246, 1);
}

.table-pagination-customer {
  border-top: 1px solid rgba(233, 238, 246, 1);

  .MuiTablePagination-caption {
    color: #8F9BBA !important;
  }

  .MuiSelect-selectMenu {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #F4F7FE !important;
    border-radius: 5px !important;
  }

  .MuiTablePagination-input {
    margin-right: 16px;
  }

  .MuiTablePagination-actions {
    margin-left: 0px;
  }

  .MuiIconButton-root {
    height: 30px;
    width: 30px;
  }

  .MuiIconButton-root:hover {
    background-color: #F4F7FE !important;
    border-radius: 5px !important;
  }
}

.MuiTablePagination-root {
  padding: 8px 0 !important;
  padding-right: 16px !important;
}

.table-pagination-customer .MuiTablePagination-input {
  margin-right: 8px !important;
}

.table-pagination-customer .MuiSelect-selectMenu {
  display: flex;
  padding: 8px 4px 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 8px !important;
  background: var(--secondary-100, #F4F7FE);
}

.table-pagination-customer .MuiTablePagination-actions {
  display: flex;
}

.table-pagination-customer .MuiTablePagination-actions .MuiButtonBase-root {
  height: 100%;
  width: auto;
  display: flex;
  padding: 8px;
  margin-left: 4px;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background: var(--secondary-100, #F4F7FE);
}

.hide-border-bottom-header-modal {
  .ant-modal-header {
    border-bottom: 0 !important;
  }
}

.form-control {
  border: 1px solid #99A4C0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1F263E;
  border-radius: 8px !important;

  .ant-select-selector {
    min-height: 37px;
    border-radius: 8px !important;

    .ant-select-selection-item {
      padding: 3px, 8px, 3px, 16px !important;
      border-radius: 6px !important;
      border-color: #1F263E !important;
      color: #1F263E !important;
      gap: 8px !important;

      .anticon-close {
        color: #1F263E !important;
      }
    }
  }
}

.form-control:disabled {
  background-color: #EFF1F5 !important;
  cursor: not-allowed !important;
}
.input-custom {
  .btn-outline-secondary:disabled {
    cursor: not-allowed;
    background-color: #EFF1F5 !important;
  }
}
.btn.btn-outline-secondary:disabled {
  background-color: #EFF1F5 !important;
  cursor: not-allowed !important;
}
.form-control-2 {
  border: 1px solid #99A4C0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1F263E;
  border-radius: 8px 0 0 8px !important;

  .ant-select-selector {
    min-height: 37px;
    border-radius: 8px !important;

    .ant-select-selection-item {
      padding: 3px, 8px, 3px, 16px !important;
      border-radius: 6px !important;
      border-color: #1F263E !important;
      color: #1F263E !important;
      gap: 8px !important;

      .anticon-close {
        color: #1F263E !important;
      }
    }
  }
}

.form-control-2:disabled {
  background-color: #EFF1F5 !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #F4F7FE !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #EFF1F5 !important;

  .ant-select-selection-item {
    background: #EFF1F5 !important;
  }

  .ant-select-selection-item {
    background-color: #EFF1F5 !important;
  }
}

.form-group {
  margin-bottom: 16px
}

.tox .tox-tinymce {
  border-radius: 8px
}

input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8F9BBA;
}

.input-group-append .btn-outline-secondary {
  border: 1px solid #99A4C0;
}

.kt-header-menu {
  margin: 0 0 0 20px;
}

.kt-container {
  padding: 0 20px !important;
}

// .card-body {
// 	padding-left: 3rem !important;
// 	padding-right: 3rem !important;
// }

.card-body {
  border-radius: 12px;
}

.lms-tab-pane {
  .ant-tabs-nav {
    border-bottom: 1px #C0C7D8;
  }

  .ant-tabs-nav-operations {
    display: none !important;
    visibility: hidden !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    background-color: #fff;
    border-radius: 8px 8px 0 0;

    .ant-tabs-tab {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #57617C;

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #338FEB;
  }

  .card {
    border-top: 1px solid #C0C7D8;
    border-radius: 0 0 12px 12px;
  }
}

.lms-live-training {
  .ant-tabs-nav-list {
    display: none !important;
  }

  .card {
    border-top: none;
    border-radius: 12px;
  }
}

.ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid #99A4C0 !important;
}

.lms-external-courses-tab-pane {
  margin-left: -20px;
  margin-right: -20px;

  .ant-tabs-nav {
    border-bottom: 1px #C0C7D8;
  }

  .ant-tabs-nav-operations {
    display: none !important;
    visibility: hidden !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    border-bottom: 1px solid #C0C7D8;

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0px;
    }

    .ant-tabs-tab {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #57617C;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0px;

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #338FEB;
  }

  .card {
    // border-top: 1px solid #C0C7D8;
    border-radius: 12px;
  }
}

.card {
  border-radius: 12px;
}

.ant-radio:not(:hover) {
  .ant-radio-inner {
    border-color: #1F263E;
  }
}

.avatar-uploader-customer {
  width: 100% !important;
  min-height: 150px;
  height: auto;

  .ant-upload {
    width: 100%;
    height: 100%;
    min-height: 150px;
  }

  .ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    min-height: 150px;
    background: white;
    padding: 12px;
  }

  .ant-upload-select-picture-card .ant-upload {
    background: #F4F7FE;
    border-radius: 12px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #8F9BBA;
    border-radius: 12px;
  }
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-color: #99A4C0;
  border-radius: 8px;
}

.upload-file-customer {
  .ant-upload {
    .ant-btn {
      border-color: #99A4C0;
      border-radius: 8px;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: #1F263E;
    }
  }
}

.drag-list-view-customer {
  border-radius: 8px;

  .ant-list-item {
    border-bottom: none !important;
    padding: 8px 0 !important;
  }

  .ant-list-item-action-split {
    visibility: hidden;
  }

  .ant-list-item-meta-avatar {
    height: 38px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ant-list-item-meta-title .form-control {
    background-color: white;
  }

  .ant-list-item-action {
    margin-left: 0;
  }

  .ant-list-item-action>li {
    padding: 0 0 0 16px;
  }
}

.drag-icon-customer {
  border: 1px dashed #8F9BBA;
  width: 40px;
  height: 40px;
  background-color: #F4F7FE;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suffix-customer {
  position: absolute;
  right: 0;
  background-color: #fff0;
  border: none;
  height: 40px;
  z-index: 9;
}

.url-embed-customer {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.date-picker-customer {
  border-color: #99A4C0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8F9BBA !important;
}

.range-picker-customer {
  border-color: #99A4C0 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  .ant-picker-input {
    input {
      text-align: center;
    }
  }

  .ant-picker-range-separator .ant-picker-separator {
    display: none;
  }

  .ant-picker-range-separator:after {
    content: " - ";
  }
}

.ant-picker.ant-picker-disabled.range-picker-customer {
  background-color: #EFF1F5 !important;
  color: #8F9BBA !important;
}

.section-avatar-uploader-customer {
  width: 50px !important;
  height: 50px !important;

  .hidden-when-customer {
    display: none;
  }

  .ant-upload {
    width: 48px !important;
    height: 48px !important;
    padding: 0px;
  }

  .ant-upload-select-picture-card {
    width: 48px !important;
    height: 48px !important;
  }

  .ant-upload-select-picture-card .ant-upload {
    background: #F4F7FE;
    border-radius: 8px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #8F9BBA;
    border-radius: 8px;
    width: 50px !important;
    height: 50px !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    width: 12px
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    padding: 4px;
  }
}

.hot-post-icon-customer {
  width: 100% !important;
  height: auto !important;

  img {
    width: 100% !important;
    height: auto !important;
  }
}

.single-choice-image-customer {
  width: 80px !important;
  height: 80px !important;

  .hidden-when-customer {
    display: none;
  }

  .ant-upload {
    width: 78px !important;
    height: 78px !important;
    padding: 0px;
  }

  .ant-upload-select-picture-card {
    width: 78px !important;
    height: 78px !important;
  }

  .ant-upload-select-picture-card .ant-upload {
    background: #F4F7FE;
    border-radius: 8px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #8F9BBA !important;
    border-radius: 8px;
    width: 80px !important;
    height: 80px !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    width: 16px
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    padding: 4px;
  }
}

.item-text-customer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  height: 40px;
  color: #1F263E;
  border-bottom: 1px solid #1F263E;
}

.blanks-text-customer {
  .view-fill-blank {
    border: 1px solid #8F9BBA !important;
    border-radius: 6px !important;
    height: 40px !important;
    width: 150px !important;
    margin-left: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.button-choose-option-customer {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #EFF1F5;
  border: none;
  color: #3B445D;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.button-choose-option-customer:hover,
.button-choose-option-customer:focus,
.button-choose-option-customer:active {
  color: #3B445D;
  background: #EFF1F5;
  border: 1px solid #8F9BBA;
}

.button-choose-option-customer:focus {
  color: #3B445D;
  background: #EFF1F5;
}

.button-choose-option-customer[disabled],
.button-choose-option-customer[disabled]:hover,
.button-choose-option-customer[disabled]:focus,
.button-choose-option-customer[disabled]:active {
  color: #8F9BBA;
}

.container-preview-customer {
  display: flex;
  margin-top: -40px;

  .left-container::-webkit-scrollbar {
    width: 6px
  }

  .left-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .left-container {
    width: 100%;
    min-height: 80vh;
    overflow-y: scroll;
    padding-right: 0px;

    .logo-container {
      z-index: 10;
      height: auto;
      padding-bottom: 20px;

      .container-image {
        width: 35%;
      }

      display: flex;
      justify-content: center;
      top: 0;
      padding-top: 30px;
      margin: 0 auto;
      width: auto;
    }

    .preview-introduction {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
    }

    .total-pass {
      padding-top: 16px;
      justify-content: center;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .preview-text {
      font-size: 20px;
      padding-top: 70px;
      padding-left: 50px;
      font-weight: bold;
      width: 80%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .preview-text-intro {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: inherit;
    }
  }

  .right-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    .card-content {
      margin-top: 50px;
      height: 140px;
      display: flex;
      width: 70%;
      cursor: pointer;

      .card-content-left {
        background-image: url(https://cdn.easygenerator.com/images/media/section-default-image.jpg);
        background-size: cover;
        background-position: center;
        width: 25%;
        background-color: rgb(210, 209, 209);
      }

      .card-content-right {
        font-size: 20px;
        padding: 10px 0 0 20px;
        font-weight: bold;
        width: 75%;
        background-color: #ffffff;
        position: relative;

        .span-next {
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin: 0;
          color: #c5c5c5;
          font-weight: normal;
        }

        .progress-section {
          .ant-progress-line {
            position: absolute;
            bottom: -7px;
            left: 0;

            .ant-progress-outer {
              .ant-progress-bg {
                height: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.container-section-customer {
  display: flex;
  margin-top: -40px;

  .left-container-section {
    min-height: 80vh;
  }

  .right-container-section {
    min-height: 80vh;

    .result-button-section {
      margin-left: 100px;

      .next-section-button {
        width: 80%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .toogle-content {
      margin-left: 100px;
    }

    .number-of-steps {
      margin-bottom: 30px;
      font-weight: bold;
    }

    .title-question {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
      color: #1F263E;
    }

    .logo-container {
      margin-left: 100px;
      margin-top: 60px;
      margin-bottom: 30px;
      padding-left: 100px;
      width: 255px;
      height: 115px;
      background-size: cover;
      object-fit: contain;
      background-position: center;
      background-image: url(https://s3.ap-southeast-1.amazonaws.com/eamd-staging/MyTraining/1634008459178-t1-png.png);
    }

    .toogle-content {
      margin-left: 100px;
    }

    .number-of-steps {
      margin-bottom: 30px;
      font-weight: bold;
    }

    .submit-section-contain {
      padding-top: 20px;
    }

    .line-container {
      height: 1px;
      width: 80%;
      border: #747474 1px solid;
    }


    .container-section-contain-result {
      display: flex;
      justify-content: center;
    }

    .result-title {
      display: flex;
      justify-content: center;
      font: inherit;
      vertical-align: baseline;
      margin-bottom: 32px;
      margin-top: 32px;
      font-family: Arial, "PT Serif", serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
      line-height: 1.4;
      color: rgb(0, 0, 0);
    }

    .result-notification {
      display: flex;
      justify-content: center;
      font: inherit;
      vertical-align: baseline;
      margin-bottom: 32px;
      margin-top: 32px;
      font-family: Arial, "PT Serif", serif;
      font-size: 18px;
      font-style: normal;
      text-decoration: none;
      line-height: 1.4;
      color: rgb(0, 0, 0);
    }

    .submit-result-button {
      display: flex;
      justify-content: center;

      .result-button {
        display: flex;
        flex-direction: column;

        button:nth-child(1) {
          color: rgb(255, 255, 255);
          height: 40px;
          margin-bottom: 20px;
          width: 135px;
        }

        button:nth-child(2) {
          color: rgb(0, 0, 0);
          height: 50px;
          margin-bottom: 20px;
        }
      }
    }

    .container-section-contain-matching {
      display: flex;
      flex-direction: row;
      width: 80%;
      margin-bottom: 10px;

      .question-matching-number {
        padding-left: 20px;
        display: flex;
        align-items: center;
        width: 45%;
        height: 35px;
        background: rgba(0, 0, 0, 0.06);
        font-size: 18px;
        color: #000;
        font-weight: 500;
      }

      .plus-matching {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .question-matching-answer {
        display: flex;
        align-items: flex-start;
        width: 45%;
        height: 35px !important;
        border: 0;
      }
    }

    .container-section-contain-input {
      label {
        display: flex;
        padding-right: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        align-items: center;
      }

      .input-fill-blank {
        width: 150px;
        height: 40px;
        border: 1.5px solid #8F9BBA;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        align-items: center;
        color: #1F263E;
        border-radius: 8px;
      }

      display: flex;
      padding-bottom: 5px;
    }

    .container-section-contain {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: #1F263E !important;

      p {
        // width: 90%;
        text-align: justify;
      }

      ol {
        li {
          padding-bottom: 15px;
        }
      }

      .ranking-question-number {
        width: 25px;
        padding-right: 15px;
        font-size: 18px;
        text-align: center;
      }

      .ranking-question {
        cursor: grab;
        width: 75%;
        font-size: 18px;
        background-color: rgba(202, 200, 200, 0.954);
        padding-bottom: 5px;
        padding-left: 10px;
      }

      margin-bottom: 50px;
    }
  }

  .marker {
    position: absolute;
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    outline: none;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      width: 50%;
      right: 0px;
      bottom: 0px;
      background: linear-gradient(rgb(0, 153, 255) 0%, rgb(0, 153, 255) 50%, rgb(0, 153, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      width: 50%;
      left: 0px;
      bottom: 0px;
      background: linear-gradient(to top, rgb(0, 153, 255) 0%, rgb(0, 153, 255) 50%, rgb(0, 153, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
    }
  }
}

.collapse-customer {
  border: none;
  background-color: #FFFFFF;

  .ant-collapse-arrow {
    color: #1F263E;
  }

  .ant-collapse-arrow {
    color: #1F263E;
  }

  .ant-collapse-header {
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border: none;
  }

  .ant-collapse-header-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .ant-collapse-content-box {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-item+.ant-collapse-item .ant-collapse-header {
    border-top: 1px solid #DFE5F9;
    padding-left: 0px;
    padding-right: 0px;
  }

}

.button-footer-customer {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 24px;
  justify-content: center;
  align-items: center;
}

.lms-btn[disabled],
.lms-btn[disabled]:hover,
.lms-btn[disabled]:focus,
.lms-btn[disabled]:active {
  background: #EFF1F5 !important;
  color: #3B445D !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.lms-btn-2[disabled],
.lms-btn-2[disabled]:hover,
.lms-btn-2[disabled]:focus,
.lms-btn-2[disabled]:active {
  background: #EFF1F5 !important;
  color: #3B445D !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.preview-single-choice-image-customer {
  width: 150px !important;
  height: 150px !important;

  .hidden-when-customer {
    display: none;
  }

  .ant-upload-list-item-info {
    width: 150px !important;
    height: 150px !important;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 8px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #8F9BBA;
    border-radius: 8px;
    width: 150px !important;
    height: 150px !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 150px;
    height: 150px;
    border-radius: 8px;
  }
}

.MuiTableRow-root .MuiTableCell-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1F263E;
}

.MuiTableRow-root .MuiTableCell-head {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1F263E;
}

.tag-marking-completed {
  border: 0 !important;
  background: #DAEBFB !important;
  color: #338FEB;
  gap: 10px !important;
  border-radius: 4px !important;
  min-height: 30px;
  min-width: 150px;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.tag-exam-completed {
  border: 0 !important;
  background: #FFEEEE !important;
  color: #F42222;
  gap: 10px !important;
  border-radius: 4px !important;
  min-height: 30px;
  min-width: 150px;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.tag-marking-progress {
  border: 0 !important;
  background: #EFF8E5 !important;
  color: #62AC03;
  gap: 10px !important;
  border-radius: 4px !important;
  min-height: 30px;
  min-width: 150px;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.tag-status-skip {
  border: 0 !important;
  background: #e2e5ec !important;
  color: #595d6e;
  gap: 10px !important;
  border-radius: 4px !important;
  min-height: 30px;
  min-width: 150px;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.tag-job-position {
  border: 0 !important;
  background: var(--orange-orange-100, #EFF1F5) !important;
  color: var(--orange-orange-500, #57617C) !important;
  gap: 10px !important;
  border-radius: 4px !important;

  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.border-top-default {
  border-top: 1px solid #DFE5F9;
}

.quiz-checkbox {
  padding: 0px 8px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1F263E;

  .ant-checkbox-inner {
    background-color: #fff;
    border-color: #1F263E;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }

  .ant-radio-inner {
    border: 1px solid #1F263E;
    background: #fff;

    &::after {
      content: none;
    }
  }

  input[type=radio],
  input[type=checkbox] {
    border: 1px solid #1F263E
  }

  .incorrect {
    color: #f24343 !important;

    input[type=radio],
    input[type=checkbox] {
      accent-color: #f24343 !important;
    }
  }
}

.list-choice-customer .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  &.incorrect {
    color: #f24343;

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #f24343;
        border: 2px solid #f24343;
      }
    }
  }
}

.color-true-01 {
  color: #338FEB;
}

.color-red-26 {
  color: #f24343 !important;
}

.bg-red-04 {
  background-color: #F04646 !important;
}

.checkbox-customer {
  .ant-checkbox-inner {
    border-color: #8F9BBA;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #1890ff !important;
  }

  .ant-checkbox-checked::after {
    border: none;
  }
}

.border-bottom-default {
  border-bottom: 1px solid #C0C7D8;
}

.noti-number {
  padding: 1px;
  width: auto;
  min-width: 17px;
  height: 17px;
  position: absolute;
  background-color: rgb(244, 34, 34);
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 11px;
  top: 6px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
}

.customer-inquiry-content {
  p {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.date-picker-customer {
  .ant-picker-clear {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
}

.ant-switch {
  border: 1px solid #1F263E;
  background-color: #FFFFFF;

  .ant-switch-handle::before {
    background-color: #1F263E;
  }

  .ant-switch-handle {
    top: 1px;
  }
}

.ant-switch-checked {
  border: none;
  background-color: #1890ff;

  .ant-switch-handle::before {
    background-color: #FFFFFF;
  }

  .ant-switch-handle {
    top: 2px;
  }
}

.h-auto {
  height: 300;
}
.height-100 {
  height: 100%;
}

.tab-cb-customer {
  margin-left: -20px;
  margin-right: -20px;

  .tab-customer {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #57617c; 
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    display: inline-flex;
    align-items: center;
  }

  .tab-customer-banner-customer {
    width: 100%;
    border-bottom: 1px solid #c0c7d8;
  }
  .tab-customer-active {
    color: #338feb; 
    border-bottom: 2px solid #1890ff;
  }
}

.ant-select-selection-placeholder {
	font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8F9BBA;
}

.ant-input-affix-wrapper > input::placeholder,
.ant-picker-input > input::placeholder,
.form-control::placeholder {
	font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8F9BBA;
}
.form-control-2::placeholder {
	font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8F9BBA;
}

.not-copy {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.ant-upload.ant-upload-disabled {
  button {
    cursor: not-allowed;
    background-color: #EFF1F5 !important;
  }
}


.group-image-sort-error:has(.li-content-err) {
	.input-sesion-name-error {
		border: 1px solid #d10202 !important;
	}
}
